import '../../App.css'
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Checkbox } from 'antd';
import axios from 'axios';
import Appconfig from '../../config/config'
import { useSelector, useDispatch } from 'react-redux';
import { getAllBets } from '../../redux/slice/openBet/openBetSlice';

function RightSidebar() {
    const userInfo = useSelector((state) => state?.userInfo?.userdata?.value); // get login user details

    const [isIframe, setIsIframe] = useState(false);
    const [showLtv, setShowLtv] = useState(false);
    const [tvUrl, setTvUrl] = useState();
    const { event_id } = useParams();
    const location = useLocation();

    const [eventsName, setEventsName] = useState([])
    const [isChecked, setIsChecked] = useState(false)
    const dispatch = useDispatch();

    const userInfos = useSelector((state) => state.bets); // Use selector to access state
    const backBets = userInfos?.bets?.backBets?.length > 0 ? userInfos?.bets?.backBets : [];
    const layBets = userInfos?.bets?.layBets?.length > 0 ? userInfos?.bets?.layBets : [];

    useEffect(() => {

        getEvents();
    }, [])

    const getEvents = async () => {
        const eventId = event_id;
        var data;
        let url;
        if (userInfo) {
            url = "getDashboardDataByeventId"
            data = JSON.stringify({
                user_id: userInfo._id,
                event_id: eventId,
            });
        } else {
            url = "getDashboardDataByEventIdWithoutUserID"
            data = JSON.stringify({
                event_id: eventId,
            });
        }
        const config = {
            method: "post",
            url: `${Appconfig.apiUrl}eventsDashboard/${url}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        try {
            const response = await axios(config);
            if (response.data.result == 1) {
                setTvUrl(response.data?.resultData[0]?.tv_link);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getOpenBetEvents();
    }, [])

    const getOpenBetEvents = async () => {

        var data = JSON.stringify({
            user_id: userInfo?._id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}reports/auraOpenBetEvents`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                // console.log('response', response);
                if (response.data.result) {
                    setEventsName(response.data.resultData);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const getOpenBetsByEvent = async (matchId) => {

        dispatch(getAllBets({
            user_id: userInfo?._id,
            match_id: matchId
        }))
    }

    return (
        <div className='bg-[linear-gradient(-180deg,_#FFFFFF_0%,_#FFFFFF_100%)] w-full max-w-[25vw] fixed hover:overflow-auto' style={{ height: 'calc(100vh - 6.25rem)' }}>
            <div className='grid grid-cols-12'>
                {
                    location.pathname.startsWith('/fullMarket') &&
                    <div className='flex justify-between col-span-12 items-center px-[7px] bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] mb-1 cursor-pointer'>
                        <div onClick={() => setIsIframe(!isIframe)} className='flex text-white text-xs leading-[25px] font-extrabold'>
                            Live Streaming
                        </div>
                    </div>
                }

                {
                    isIframe ?

                        <div id="streamingBox" className="tv-fix">
                            <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" contenteditable="false" frameborder="0" scrolling="yes" id="tvframe" onLoad={() => setShowLtv(true)} referrerpolicy="no-referrer" src={tvUrl} style={{ width: "100vw", height: "14rem", display: showLtv ? "" : "none" }}></iframe>

                        </div>
                        :
                        <></>
                }

                <div className='flex justify-between col-span-12 items-center px-[7px] bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] cursor-pointer'>
                    <div className='flex text-white text-xs leading-[25px] font-extrabold'>
                        Open Bets
                    </div>
                </div>


                <div className='col-span-12 mb-2 mt-2 p-1'>
                    <select className='cursor-pointer p-[3px] border-[1px] border-[#000000] w-full rounded-[5px]' onChange={(e) => getOpenBetsByEvent(e.target.value)}>
                        <option>Select Market</option>
                        {
                            eventsName?.map(events => {
                                return (
                                    <option value={events.match_id}>{events.event_name}</option>

                                )
                            })
                        }
                    </select>
                </div>

                <div className='col-span-12'>

                    {
                        userInfos?.bets ?
                            <>
                                <div className='p-1 text-[#000000] font-semibold text-base bg-[#ced5da] mb-[2px]'>
                                    Matched</div>

                            </>
                            :
                            <></>
                    }

                    <div className='p-1'>
                        {backBets.length > 0 && (
                            <>
                                <div className='grid grid-cols-12 p-1 '>
                                    <div className='col-span-5 text-[11px]'>
                                        Back (Bet For)
                                    </div>
                                    <div className='col-span-2 text-[11px]'>
                                        Odds
                                    </div>
                                    <div className='col-span-2 text-[11px]'>
                                        Stake
                                    </div>
                                    <div className='col-span-3 text-[11px]'>
                                        Profit
                                    </div>
                                </div>
                                {backBets.map(data => (
                                    <>
                                        {
                                            isChecked &&
                                            <div className='text-[11px] p-[2px] bg-[#d4e8f8]'>
                                                Ref : {new Date(data.createdAt).toLocaleString()}
                                            </div>
                                        }

                                        <div key={data.id} className='grid grid-cols-12 gap-2 pl-1 pt-3 pb-3 bg-[#beddf4] border-b-[1px] border-b-[#67afe5]'>
                                            <div className='col-span-5 flex gap-2 justify-evenly'>
                                                <div className='text-[11px] bg-[#7dbbe9] p-1 rounded-[3px]'>Back</div>
                                                <div className='text-[11px] font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap block'>
                                                    {data.place_name}
                                                </div>

                                            </div>
                                            <div className='col-span-2 text-[11px]'>
                                                {data.price_val}
                                            </div>
                                            <div className='col-span-2 text-[11px]'>
                                                {data.stake}
                                            </div>
                                            <div className='col-span-3 text-[11px]'>
                                                {data.profit.toFixed(0)} (-{data.loss.toFixed(0)})
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </>
                        )}

                        {layBets.length > 0 && (
                            <>
                                <div className='grid grid-cols-12 p-1'>
                                    <div className='col-span-5 text-[11px]'>
                                        Lay (Bet Against)
                                    </div>
                                    <div className='col-span-2 text-[11px]'>
                                        Odds
                                    </div>
                                    <div className='col-span-2 text-[11px]'>
                                        Stake
                                    </div>
                                    <div className='col-span-3 text-[11px]'>
                                        Liability
                                    </div>
                                </div>
                                {layBets.map(data => (
                                    <>
                                        {
                                            isChecked &&
                                            <div className='text-[11px] p-[2px] bg-[#f7e7eb]'>
                                                Ref : {new Date(data.createdAt).toLocaleString()}
                                            </div>
                                        }
                                        <div key={data.id} className='grid grid-cols-12 gap-2 pt-3 pb-3 pl-1 bg-[#f3dce2] border-b-[1px] border-b-[#dfa3b3]'>
                                            <div className='col-span-5 flex gap-2 justify-evenly'>
                                                <div className='text-[11px] bg-[#dfa3b3] p-1 rounded-[3px]'>Lay</div>
                                                <div className='text-[11px] font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap block'>
                                                    {data.place_name}
                                                </div>

                                            </div>
                                            <div className='col-span-2 text-[11px]'>
                                                {data.price_val}
                                            </div>
                                            <div className='col-span-2 text-[11px]'>
                                                {data.stake}
                                            </div>
                                            <div className='col-span-3 text-[11px]'>
                                                {data.loss.toFixed(0)}
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </>
                        )}
                    </div>
                    {
                        userInfos?.bets &&
                        <div className='p-2 text-[14px]'>
                            <Checkbox onChange={() => setIsChecked(!isChecked)}>Bet Info</Checkbox>
                        </div>
                    }

                </div>

            </div>
        </div>
    )
}


export default RightSidebar;
